import React from 'react';
// import PropTypes from 'prop-types';
import axios from "axios/index";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: ''
    };

    this.fetchLink();
  }

  fetchLink() {
    return axios
      .get('/store/cart_link')
      .then((response) => {
        this.setState({link: response.data});
      });
  }

  empty() {
    return this.state.link == '' || this.state.link.includes(' 0');
  }

  render() {
    if (this.empty()) {
      return '';
    }
    else {
      const cartbar = document.getElementById('cartbar')
      const storebar = document.getElementById('store-nav')

      if(cartbar != null && /^\/store\/checkout/.exec(window.location.pathname) || /^\/store\/cart/.exec(window.location.pathname) || /^\/store\/product/.exec(window.location.pathname)){
        cartbar.style.display = 'none'
      }

      if(/^\/store\/checkout/.exec(window.location.pathname) || /^\/store\/cart/.exec(window.location.pathname)){
        storebar.style.display = 'none'
      }
      return (
        <div id='showcart'>
          <div className='content'>
            <a href='/store/cart' className='cart-checkout'>CHECKOUT ▶︎</a>
            <span dangerouslySetInnerHTML={{__html: this.state.link}}></span>
          </div>
        </div>
      );
    }
  }
}

Cart.propTypes = {
};

export default Cart;
