import React from 'react';
import ReactDOM from 'react-dom';
// import 'babel-polyfill';
import NavBar from '../header/nav_bar';
import Cart from '../header/cart';

const navbar = document.getElementById('navbar');
const logo = navbar.getAttribute('data-logo-path');
const username = navbar.getAttribute('data-username');
const user_id = navbar.getAttribute('data-id');
import { setTokenForAxios } from '../packs/helpers/form_support';

const brand = {
  linkTo: '/',
  logo: logo
};


const generateMenuLinks = () => {
  const links = [
    {
      text: 'Blog',
      dropdown: false,
      linkTo: '/blog'
    },
    {
      
      text: 'About',
      dropdown: true,
      links: [
        {
          linkTo: '/about/government-certifications',
          text: 'Non-Profit Certifications',
        },
        {
          linkTo: '/about/get-involved',
          text: 'Get Involved',
        },
        {
          linkTo: '/about',
          text: 'Who we are',
        },
        {
          linkTo: '/faq',
          text: 'FAQ',
        },
      ]
    }
  ];
  if (username) {
    links.push(
      {
        text: 'Learn',
        dropdown: true,
        links: [
          {
            linkTo: '/officiant-training',
            text: 'Wedding Officiant Training',
          },
          {
            linkTo: '/ceremony-scripts',
            text: 'Ceremony Script Library',
          },
          {
            linkTo: '/weddings-by-state',
            text: 'Weddings by State',
          },
          {
            linkTo: 'https://courses.theamm.org/wp-login.php?redirect_to=https%3A%2F%2Fcourses.theamm.org%2F',
            text: 'Courses',
          },
          {
            linkTo: 'https://www.officiantaccelerator.com/',
            text: 'Events'
          }
        ]
      }
    )
  } else {
    links.push(
      {
        text: 'Learn',
        dropdown: true,
        links: [
          {
            linkTo: '/officiant-training',
            text: 'Wedding Officiant Training',
          },
          {
            linkTo: '/ceremony-scripts',
            text: 'Ceremony Script Library',
          },
          {
            linkTo: '/weddings-by-state',
            text: 'Weddings by State',
          },
          {
            linkTo: 'https://courses.theamm.org/',
            text: 'Courses',
          },
          {
            linkTo: 'https://www.officiantaccelerator.com/',
            text: 'Events'
          }
        ]
      }
    )
  }

  links.push(
    {
      text: 'Store',
      dropdown: true,
      links: [
        {
          linkTo: '/store#credentials',
          text: 'Minister Credentials',
        },
        {
          linkTo: '/store#certificates',
          text: 'Marriage Certificates',
        },
        {
          linkTo: '/store#apparel',
          text: 'Officiant Apparel',
        },
        {
          linkTo: '/store#books',
          text: 'Wedding Books',
        },
        {
          linkTo: '/store',
          text: 'Store Home',
        },
      ]
    }
  );

  if (username) {
    links.push(
      {
        text: 'My Account',
        dropdown: true,
        links: [
          {
            linkTo: '/ministers/' + user_id + '/profile',
            text: 'Profile',
          },
          {
            linkTo: '/my-weddings',
            text: 'Weddings',
          },
          {
            linkTo: '/account-settings',
            text: 'Settings',
          },
          {
            linkTo: '/users/sign_out',
            text: 'Logout',
          }
        ]
      },
    )
  } else {
    links.push(
      {
        linkTo: '/users/sign_in',
        text: 'Login',
      },
    );

    if (window.location.href.indexOf("store") > -1) {
      links.push(
        {
          linkTo: '/ordination-application',
          text: 'Ordain Me',
        },
      );
    } else {
      links.push(
        {
          linkTo: '/ordination-application',
          text: 'Get Ordained',
        },
      );
    }

  }

  return links;
};

document.addEventListener("DOMContentLoaded", () => {
  setTokenForAxios();

  ReactDOM.render(
    <NavBar brand={brand} links={generateMenuLinks()}/>,
    document.getElementById('navbar')
  );

  
  ReactDOM.render(
    <Cart />,
    document.getElementById('cartbar')
  );
});
