import React from 'react';
import PropTypes from 'prop-types';
import NavLink from './nav_link';
import NavLinkDropdown from './nav_link_dropdown';
import NavHamburger from './nav_hamburger';
import NavCart from './nav_cart';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.burgerToggle = this.burgerToggle.bind(this);
    this.linkSelected = this.linkSelected.bind(this);
    this.handleBrowserNavigated = this.handleBrowserNavigated.bind(this);
    window.addEventListener('pageshow', this.handleBrowserNavigated);
  }

  handleBrowserNavigated(e) {
    if (e.persisted) {
      this.closeNav();
    }
  }

  linkSelected() {
    this.closeNav();
  }

  closeNav() {
    this.setState({open: false});
  }

  renderedLinks() {
    const linkSelected = this.linkSelected;

    return this.props.links.map(function(link) {
      if (link.dropdown) {
        return (
          <NavLinkDropdown key={link.text} links={link.links} text={link.text} active={link.active} selected={linkSelected} />
        );
      } else {
        return (
          <NavLink key={link.text} linkTo={link.linkTo} text={link.text} active={link.active} selected={linkSelected} />
        );
      }
    });
  }

  navContainerClasses() {
    var classes = 'nav-container';
    if (this.state.open) {
      classes += ' nav-open';
    } else {
      classes += ' nav-closed';
    }

    return classes;
  }

  burgerToggle() {
    this.setState({open: !this.state.open});
  }

  render() {
    return (
      <div className={this.navContainerClasses()}>
        <nav className='content'>
          <NavHamburger onClick={ this.burgerToggle } open={this.state.open} />
          <a href='/'><img id='navlogo' src='https://amm-prod.s3-us-west-2.amazonaws.com/site/amm_logo_white.svg' alt='American Marriage Ministries Logo'/></a>
          <NavCart />
          <div className="nav-wide">
            <div className="wide-div">
              <ul className='wide-links'>
                {this.renderedLinks()}
              </ul>
            </div>
          </div>
          <div className="nav-narrow">
            <ul className='narrow-links'>
              {this.renderedLinks()}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

NavBar.propTypes = {
  brand: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default NavBar;
