import React from "react";
import PropTypes from "prop-types";

class NavLink extends React.Component {
  constructor(props) {
    super(props);

    this.selected = this.selected.bind(this);
  }

  selected() {
    this.props.selected();
  }

  render() {
    return (
      <li className={(this.props.active ? "active" : "")}>
        <a className='nav-link' href={this.props.linkTo} onClick={this.selected}>{this.props.text}</a>
      </li>
    );
  }
}

NavLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.func.isRequired,
};

export default NavLink;
