import React from 'react';
import axios from "axios/index";

class NavCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: ''
    };
    this.fetchLink();
  }

  fetchLink() {
    return axios
      .get('/store/cart_link')
      .then((response) => {
        this.setState({link: response.data});
      });
  }

  render() {
    return (
      <span dangerouslySetInnerHTML={{__html: this.state.link}}></span>
    );
  }
}

NavCart.propTypes = {
};

export default NavCart;
