import axios from "axios/index";

export const setTokenForAxios = () => {
  const tokenMetaTag = document.querySelector('meta[name="csrf-token"]');
  const token = tokenMetaTag ? tokenMetaTag.content : null;

  axios.defaults.headers.common["X-CSRF-Token"] = token;
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
};

/**
 *
 * @param {Array<Record<string, string | number>>} statesOrCountries
 * @returns {Array<{label: string, value: number}> | undefined}
 * @example
 * getOptions([{name: 'Alabama', id: 1}]) // [{label: 'Alabama', value: 1}]
 * getOptions([{foo: 'bar', baz: 1}]) // null
 * getOptions([{name: 'Alabama', id: 1, foo: 'bar', baz: 0.1}]) // [{label: 'Alabama', value: 1}]
 */
const getOptions = (statesOrCountries) => {
  if (!statesOrCountries) return;
  
  return statesOrCountries
    .map((option) => {
      return { label: option.name, value: option.id };
    });
};

/**
 *
 * @param {string} dataType
 * @returns {ReturnType<getOptions>}
 * @see {@link getOptions}
 * @example
 * getStatesOrCountries('data-states')
 * getStatesOrCountries('data-countries')
 *
 */
const getStatesOrCountries = (dataType) => {
  const dataContainer = document.querySelector(`[${dataType}]`);

  // Skips if no data container is found
  if (!dataContainer) {
    console.log(`No ${dataType} element found`);
    return;
  }
  const json = dataContainer.getAttribute(dataType);
  // Skips if no data would be parsed
  if (json.length === 0) return;

  const statesOrCountries = JSON.parse(json);

  return getOptions(statesOrCountries);
};

export const stateOptions = () => {
  return getStatesOrCountries("data-states");
};

export const countryOptions = () => {
  return getStatesOrCountries("data-countries");
};

export const religionOptions = () => {
  const religionJson = document
    .querySelector("[data-religions]")
    .getAttribute("data-religions");

  if (religionJson.length > 0) {
    const religions = JSON.parse(religionJson);

    const separatedReligions = {};

    separatedReligions.topLevel = religions
      .filter((option) => {
        return !option.parent_id;
      })
      .map((option) => {
        return { label: option.name, value: option.id };
      });

    separatedReligions.sub = religions
      .filter((option) => {
        return !!option.parent_id;
      })
      .reduce((obj, r) => {
        if (!obj[r.parent_id]) {
          obj[r.parent_id] = [];
        }

        obj[r.parent_id].push({ label: r.name, value: r.id });
        return obj;
      }, {});

    return separatedReligions;
  }
};

export const ceremonyTypeOptions = () => {
  const ceremonyTypesJson = document
    .querySelector("#ceremony-types")
    .getAttribute("data-ceremony-types");

  if (ceremonyTypesJson.length > 0) {
    const ceremonyTypes = JSON.parse(ceremonyTypesJson);

    return ceremonyTypes.map((type) => {
      const humanReadable = type
        .replace(/[_-]/g, " ")
        .replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });

      return { label: humanReadable, value: type };
    });
  }
};

export default {};
