import React from 'react';
import PropTypes from 'prop-types';

class NavHamburger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open
    };

    this.onClick = this.onClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({open: newProps.open});
  }

  onClick() {
    this.props.onClick();
  }

  burgerContainerClasses() {
    var classes = 'hamburger hamburger--collapse';
    if (this.state.open) {
      classes += ' is-active'
    }

    return classes;
  }

  render() {
    return (
      <button className={this.burgerContainerClasses()} type="button" onClick={ this.onClick } aria-label="Open Menu">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    );
  }
}

NavHamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NavHamburger;
