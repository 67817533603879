import React from "react";
import PropTypes from "prop-types";
import NavLink from './nav_link';

class NavLinkDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.changeActive = this.changeActive.bind(this);
    this.setActive = this.setActive.bind(this);
    this.setNotActive = this.setNotActive.bind(this);
    this.setActiveIfMobile = this.setActiveIfMobile.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.setActiveIfMobile();
  }

  setActiveIfMobile(){
    if (window.innerWidth < 800) {
      this.setState({active: true})
    } else {
      this.setState({active: false})
    }
  }

  changeActive() {
    this.setState({ active: !this.state.active })
  }

  setActive() {
    if (!this.state.active && window.innerWidth > 800) {
      this.setState({active: true})
    }
  }

  setNotActive() {
    if (this.state.active && window.innerWidth > 800) {
      this.setState({active: false})
    }
  }

  render() {
    const selected = this.props.selected;
    var links = this.props.links.map(function(link) {
      return (
        <NavLink key={link.text} linkTo={link.linkTo} text={link.text} active={link.active} selected={selected} />
      );
    });
    return (
      <li className={"nav-dropdown " + (this.state.active ? "active" : "")} onClick={this.changeActive} onMouseOver={this.setActive} onMouseOut={this.setNotActive}>
        <div className="dropdown-toggle nav-link" data-toggle="nav-dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          {this.props.text}
        </div>
        <ul className="nav-dropdown-menu">
          {links}
        </ul>
      </li>
    );
  }
}

NavLinkDropdown.propTypes = {
  links: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  selected: PropTypes.func.isRequired,
};

export default NavLinkDropdown;
